'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentTypesManager

 # @description

###
angular
  .module 'mundoAdmin.incidents'
  .factory 'IncidentTypesManager',(
    MundoDefaultManager
  ) ->
    IncidentTypesManagerBase =  new MundoDefaultManager()

    IncidentTypesManagerBase.setUrl('lpa/incidents/types')
    IncidentTypesManagerBase.setActions(['list', 'print', 'search'])
    IncidentTypesManagerBase.setSearchFields(['label', 'code'])

    IncidentTypesManagerBase.getListSchema = ->
      [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'code'
          title: 'datatable.label.code'
          sort: 'code'
        ,
          key: 'parentIncidentType.label'
          title: 'datatable.label.parent'
          sort: 'parentIncidentType.label'
      ]

    IncidentTypesManagerBase
